import { createBrowserRouter, Outlet } from "react-router-dom";
import { DataProvider } from "../contexts/DataContext";
import { SqlLabProvider } from "../contexts/SqlLabContext";
import { Role } from "../utils/roles";

// Pages
import Home from "../pages/Home";
import Dashboard from "../pages/Dashboard";
import EmbeddedDashboard from "../pages/EmbeddedDashboard";
import SqlLab from "../pages/SqlLab";
import GoogleLoginPage from "../pages/GoogleLogin";
import Error from "../pages/Error";
import Error404 from "../pages/Error404";
import Error403 from "../pages/Error403";

import PrivateRoute from "../utils/privateRoutes";
import GrossProfitDetail from "../components/GrossProfitDetails";
import Layout from "../components/Layout";

const {
  ROLE_DIRECTORS,
  ROLE_RESOURCE_MANAGEMENT_TEAM,
  ROLE_RESOURCE_MANAGERS,
  ROLE_HR_MANAGERS,
  ROLE_RECRUITMENT_MANAGER,
  ROLE_RECRUITMENT_STAFF,
  ROLE_HR_STAFFS,
  ROLE_EXECUTIVES,
  ROLE_ACCOUNTS,
  ROLE_PROJECT_MANAGER,
  ROLE_RMG,
  ROLE_ACCOUNT_STAFF,
} = Role;

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <PrivateRoute
        roles={[
          ROLE_DIRECTORS,
          ROLE_RESOURCE_MANAGEMENT_TEAM,
          ROLE_RESOURCE_MANAGERS,
          ROLE_HR_MANAGERS,
          ROLE_RECRUITMENT_MANAGER,
          ROLE_RECRUITMENT_STAFF,
          ROLE_HR_STAFFS,
          ROLE_EXECUTIVES,
          ROLE_ACCOUNTS,
          ROLE_PROJECT_MANAGER,
          ROLE_RMG,
          ROLE_ACCOUNT_STAFF,
        ]}
      >
        <DataProvider>
          <Home />
        </DataProvider>
      </PrivateRoute>
    ),
    errorElement: <Error />,
  },
  {
    path: "/login",
    element: <GoogleLoginPage />,
    errorElement: <Error />,
  },
  {
    path: "/dashboard/:id",
    element: (
      <PrivateRoute
        roles={[
          ROLE_DIRECTORS,
          ROLE_RESOURCE_MANAGEMENT_TEAM,
          ROLE_RESOURCE_MANAGERS,
          ROLE_HR_MANAGERS,
          ROLE_RECRUITMENT_MANAGER,
          ROLE_RECRUITMENT_STAFF,
          ROLE_HR_STAFFS,
          ROLE_EXECUTIVES,
          ROLE_ACCOUNTS,
          ROLE_PROJECT_MANAGER,
          ROLE_RMG,
          ROLE_ACCOUNT_STAFF
        ]}
      >
        <DataProvider>
          <Layout>
            <Outlet />
          </Layout>
        </DataProvider>
      </PrivateRoute>
    ),
    errorElement: <Error />,
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
      {
        path: "detail/:month/:reportType",
        element: <GrossProfitDetail />,
      },
    ],
  },
  {
    path: "/embedded-dashboard/:id",
    element: <EmbeddedDashboard />,
    errorElement: <Error />,
  },
  {
    path: "/sqllab",
    element: (
      <PrivateRoute roles={[ROLE_DIRECTORS]}>
        <DataProvider>
          <SqlLabProvider>
            <SqlLab />
          </SqlLabProvider>
        </DataProvider>
      </PrivateRoute>
    ),
    errorElement: <Error />,
  },
  {
    path: "/404",
    element: <Error404 />,
  },
  {
    path: "/403",
    element: <Error403 />,
  },
]);

export default router;
